import { Mockup } from '@interfaces/mockup.interface';
import { GetTransferStateV2 } from '@interfaces/transfer-state.interface';

export const GET_TRANSFER_STATE_MOCKUP: Mockup = {
  success: [{
    response: {
      state: 'Rechazada'
    } as GetTransferStateV2
  }],
  failures: [{
    response: {
      error: {
        error: 40001
      }
    }
  }]
};
