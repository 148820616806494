import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { HttpClientInterceptor } from '@interceptors/http-client-interceptor/http-client-interceptor';
import { GetTransferStateV2 } from '@interfaces/transfer-state.interface';
import { ErrorUtils } from '@utils/error.utils';
import { Utils } from '@utils/utils';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GET_TRANSFER_STATE_MOCKUP } from './mocks/transfer.mock';

@Injectable({
  providedIn: 'root'
})
export class TransferService {
  private baseUrl = `${environment.proxyApiUrl}transfer`;
  constructor(
    private http: HttpClientInterceptor,
    private errorUtils: ErrorUtils,
    private utils: Utils,
  ) { }

  public getTransferState(rut: string, date: Date): Observable<GetTransferStateV2> {
    const url = `${this.baseUrl}/v2/state`;
    const data = { rut, date: this.utils.getFormattedDate(date) };
    return this.http.get(url, GET_TRANSFER_STATE_MOCKUP, data)
      .pipe(catchError((error) => this.errorUtils.generalCatchError(error)));
  }
}
