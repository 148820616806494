import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalComponent } from '@components/modal/modal.component';
import { EMAIL_PATTERN, PHONE_PATTERN } from '@constants/regex.constant';
import { ContactReason } from '@interfaces/contact.interface';
import { ModalResponseContact } from '@interfaces/help-button.interface';
import { ModalController, NavParams } from '@ionic/angular';
import {
  CONTACT_MODAL_TEXT, CONTACT_OPTION_LABELS, NAMES_FORM_FIELDS
} from '@pages-content/contact.constant';
import { FontService } from '@providers/font/font.service';
import { ContactService } from '@services/contact/contact.service';
import { ValidateRut } from '@validators/rut.validator';

@Component({
  selector: 'app-modal-contact',
  templateUrl: './modal-contact.component.html',
  styleUrls: ['./modal-contact.component.scss'],
})
export class ModalContactComponent extends ModalComponent implements OnInit {
  public fieldNames = NAMES_FORM_FIELDS;
  public content = CONTACT_MODAL_TEXT;
  public contentOptions = CONTACT_OPTION_LABELS;
  public contactReasons: Array<ContactReason>;
  public form: UntypedFormGroup = new UntypedFormGroup({
    rut: new UntypedFormControl('', [ValidateRut, Validators.required]),
    fatherLastName: new UntypedFormControl('', [Validators.required]),
    motherLastName: new UntypedFormControl(''),
    name: new UntypedFormControl('', [Validators.required]),
    phoneNumber: new UntypedFormControl('', [Validators.minLength(9), Validators.maxLength(9), Validators.pattern(PHONE_PATTERN)]),
    email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]),
    reasonCode: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', [Validators.required])
  });
  public isSubmit = false;

  public get rut() { return this.form.get('rut') as UntypedFormControl; }
  public get fileName() { return this.form.get('fileName') as UntypedFormControl; }
  public get addedFile() { return this.form.get('addedFile') as UntypedFormControl; }
  public get reasonCode() { return this.form.get('reasonCode') as UntypedFormControl; }
  public get labelButtonFile() { return !this.fileName.value ? this.contentOptions.upload : this.contentOptions.update; }
  public get isButtonDisabled(): boolean { return this.form.invalid || this.isSubmit; }

  constructor(
    public font: FontService,
    private contactService: ContactService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
  ) {
    super(params, modalCtrl);
  }

  ngOnInit() {
    this.getContactReasons();
  }

  private getContactReasons() {
    this.contactService.getReasons().subscribe((response) => this.contactReasons = response);
  }

  public sendFormData() {
    if (this.form.invalid || this.isSubmit) { return; }
    this.isSubmit = true;
    this.contactService.contact(this.form.value)
      .subscribe(
        (response) => this.closeModal({ type: 'success', data: { ...response } } as ModalResponseContact),
        (error) => this.closeModal({ type: 'error', data: error } as ModalResponseContact),
      );
  }
}
